var xhr, scheduled = false, _throttleDelay = 200, $body, $modal, $secondaryModal,  resizeThrottleDelay, $window,clickevent='click',refered;
var editorExtensionId = "cfhjhmphknppmdadefofodicemlogjmn";


 
$(function(){
     
 $('.close-responsive-nav,.responsive-nav-overlay,.show-menu .burger-btn').click(function($e){
        $('body').removeClass('show-menu');
        $e.preventDefault();
    });
    
    $('.burger-btn').click(function($e){
        $('body').toggleClass('show-menu');
         $(this).removeClass('init-burger');
        
        $('.megamenu-product-link').removeClass('active-hovered').removeClass('hovered');
        $( ".brand-nav-item" ).removeClass('hovered-brand');
        $('.megamenu-product-image').hide();
        $e.preventDefault();
    });
}); 


function reinitCarousel() {
  if ($('.hero').hasClass('slick-initialized')) {
    $('.hero').slick('destroy');
    Heros.init('.hero');
  }      
}


$(document).ready(function () {    

    $body = $('body'); 
    $window = $(window);
    $body.find('img').addClass('onErrorCallbak');
 
    $('.way-point-animate').waypoint(function() { 
        $(this.element).addClass('shown');
    }, {
        offset: '98%'
    });     


   


    if ("ontouchstart" in window || navigator.msMaxTouchPoints)
    {
        $('body').addClass('touch');
    } else {
        $('body').addClass('no-touch');
    }   
    
    

    $modal = $('#modal');
    $secondaryModal = $('#modalSecondary');

    $(document).on('ifToggled','.toggle-select-all', function(event){
        var target = $(this).data('toggleSelectAllTarget');
        $(target).each(function () {
            $(this).iCheck('toggle');
        });
    });
    
    $(document).on('click','a[data-target="#search-collapsible"]',function(){
        GA.register('button','click','search');
        scroll2position(0);
        setTimeout(function(){
            $('input[name="search_param"]').focus();
        },500);
    });


    $(document).on('click','#filter-categories .category-item  a',function(e){
        e.preventDefault();
        var category_id = $(this).data('category_id');
        var url = $(this).data('url');
        var title = $(this).data('title');
        
        $('category-item').removeClass('active');
        $(this).addClass('active');
        var p = $(this).closest('.partial');
        p.data( "subcategory_id",'' );
        $('select[name="subcategory_id"]').val(null).trigger('change.select2');
        var s = $('.next-draw');
        p.find('.widget-content').html('');
        changeUrl(title,url);
        if(p.find('.top-filters .category select[name="category"]').length>0){
            p.find('.top-filters .category select[name="category"]').data('category_parent',category_id);
        }
        p.data('page',1);
      
      
        p.data('category_id',category_id);
        p.addClass('loading');
        if(s.length > 0){
            scroll2element(s.get());    
        }else{
            scroll2element($(this).closest('.partial').get());
        }
        load_partial(p);
        return false;
    });


    if($('.hero-full').length>0){
         $('header').addClass('header-scrolled');
        var scrolled_from_top = $(document).scrollTop();
            if(scrolled_from_top > Number( $('.hero-full').innerHeight() - 300 ) ){
                $('header').removeClass('header-scrolled');
            }else{
                $('header').addClass('header-scrolled');
            }
             $(window).scroll(function(){
                var scrolled_from_top = $(document).scrollTop();        
                if(scrolled_from_top >  Number( $('.hero-full').innerHeight() - 300 )   ){
                    $('header').removeClass('header-scrolled');
                }else{
                    $('header').addClass('header-scrolled');
                }
            });
    }
   

     
     if($(window).scrollTop() > 50) {
          $('body').addClass('scrolled');  
    }
    
     var lastScrollTop = 0;
     $(document).on('scroll',function() {

        _scrollTop = $(window).scrollTop();
        if (_scrollTop >= 50) {
            $('body').addClass('scrolled');  
             $('header').addClass('show-shadow'); 
        } else {
            $('body').removeClass('scrolled');
             $('header').removeClass('show-shadow'); 
        }
    });

    

    $('.go-to-anchor').click(function(){
        var anchor = $(this).attr('href');
        $('html, body').stop().animate({scrollTop: $(anchor).offset().top - 100}, 'slow');
        return false;
    });    

    $modal.on('hidden.bs.modal', function (e) {
        $(this).empty();

    });

    $(document).on('click', '.scroll-to-element', function (e) {
        scroll2element($(this).attr('href'));
        e.preventDefault();
    });

    $(document).on('click', '.close-collapsible', function () {
        $(this).closest('.in').find('input:text').val('');
        $(this).closest('.in').collapse('hide');
        return false;
    });

    $(document).on('click', '.collapse-all', function () {
        $('.panel-collapse').collapse('hide');
        $(this).toggle();
        $('.expand-all').toggle();
        return false;
    });

    $(document).on('click', '.expand-all', function () {
        $(this).toggle();
        $('.collapse-all').toggle();
        $('.panel-collapse').collapse('show');
        return false;
    });



    
    $(document).on('click', ".ajax-modal", function (e) {
        try{
            e.preventDefault();
            openAjaxModal($(this).attr('href'));
        }catch(Error){
            console.log(Error);
        }

        return false;
    });
    $(document).on('click', ".inline-modal", function (e) {

        e.preventDefault();
        var t = $(this).data('href');
        var markup = $(t).clone();
        $modal.removeData('bs.modal');

        $('#modal').html(markup).find(t).show();
        skinFormElements('#modal');
        $modal.modal('show');
        return false;
    });

    $(document).on('click', '[data-action="submit"]', function () {
        var target = $(this).data('target');
        $('form[name="'+target+'"]').submit();
        return false;
    });
    $(document).on('click', '[data-action="submit-with-target"]', function () {
        var target = $(this).data('target');
        $(target).submit();
        return false;
    });
    $(document).on('click', '.scroll-top', function () {
        scroll2position(0);
        return false;
    });

    $(document).on('click','.filters .update-results',function(){


        var target = $(this).data('target'),container = $(this).closest('.filters-content');

        container.find('.filter-group').each(function(){
            var k = $(this).data('filterGroup');
            var key = 'attributes['+k+']';
            if(k == 'cid'){
                key = 'cid';
            }else if(k == 'not_purchased'){
                key = 'not_purchased';
            }

            if(k == 'not_purchased'){
                $('form[name="' + target + '-nav"] input[name="' + key + '"]').remove();
            }else{
                $('form[name="' + target + '-nav"] input[name="' + key + '[]"]').remove();
            }
 

            $(this).find('input:checkbox:checked,input:hidden.hidden-filter').each(function(){
                var value = $(this).val();
                if(value!=''){
                    var values = value.split(',');
                    for(var i in values){
                        if(k == 'not_purchased'){
                            $('form[name="' + target + '-nav"]').append('<input type="hidden" name="' + key + '" value="' + values[i] + '"/>');
                        }else{
                            $('form[name="' + target + '-nav"]').append('<input type="hidden" name="' + key + '[]" value="' + values[i] + '"/>');
                        }

                    }

                }

            });
        });

        $('.filters .reset-filters').removeClass('hidden');

        setTimeout(function(){
            deepLinking(target);
            filter(target);
        },500);

        return false;
    });
    $(document).on('click','.filters .reset-filters a',function(){
        var target = $(this).data('target');
        $('.filters input:checkbox').each(function(){
            if($(this).hasClass('select-all')){
                $(this).iCheck('check');
            }else{
                $(this).iCheck('uncheck');
            }
            var value = $(this).val();
            var key = $(this).closest('.filter-group').data('filterGroup');

            if(value!=''){
                var values = value.split(',');
                for(var i in values){
                    var value = values[i];
                    $('form[name="' + target + '-nav"] input:hidden[name="' + key + '[]"][value="'+value+'"]').remove();
                    $('form[name="' + target + '-nav"] input:hidden[name="attributes[' + key + '][]"][value="'+value+'"]').remove();
                }

            }
        });
        $('.filters .reset-filters').addClass('hidden');
        // history.pushState(true, '' , '');
        deepLinking(target);
        filter(target);
        return false;
    });
    $(document).on('click', 'button.filter', function (e) {
        var key = $(this).data('filterName'),
            target = $(this).data('target'),
            value = $(this).data('value'),
            label = $(this).text(),
            multiple = $(this).data('multiple');

        if($(this).hasClass('active')){
            return false;
        }

        $('form[name="' + target + '-nav"] input[name="' + key + '"]').val(value);
        $('.filter[data-filter-name="' + key + '"]').removeClass('active');
        $(this).addClass('active');
        filter(target);
        scroll2element($(this));
        e.preventDefault();
    });

    $(document).on('click', 'a.filter', function (e) {
        var key = $(this).data('filterName'),
            target = $(this).data('target'),
            value = $(this).data('value'),
            label = $(this).text(),
            multiple = $(this).data('multiple');

        if($(this).closest('li').hasClass('active') || $(this).hasClass('active')){
            return false;
        }

        if (multiple == true) {

            if ($(this).parent().hasClass('active')) {
                // it's already checked. Uncheck it please
                $(this).parent().removeClass('active');
                $('form[name="' + target + '-nav"] input[name="' + key + '[]"][value="' + value + '"]').remove();

            } else if (value == '') {
                $('a.filter[data-filter-name="' + key + '"]').parent().removeClass('active');
                $('form[name="' + target + '-nav"] input[name="' + key + '[]"]').remove();
            } else {
                // Not checked
                $(this).parent().addClass('active');
                $('form[name="' + target + '-nav"]').append('<input type="hidden" name="' + key + '[]" value="' + value + '"/>');
            }
        } else {
            $('.filter[data-filter-name="' + key + '"]').parent().removeClass('active');
            $(this).parent().addClass('active');
            var t = $(this).closest('.filter-group').find('.current-filter-value');
            if(value!==''){
                t.html(label);
            }else if(value===''){
                t.html(t.data('default'));
            }

            $('form[name="' + target + '-nav"] input[name="' + key + '"]').val(value);
        }

        if(key == 'sort_term'){
            if(value!==''){
                $(this).closest('ul').find('.reset').removeClass('hidden');
                $(this).closest('ul').find('.reset').next().removeClass('hidden');
            }else{
                $(this).closest('ul').find('.reset').addClass('hidden');
                $(this).closest('ul').find('.reset').next().addClass('hidden');
            }

            GA.register('Filters','Sort',value);

            $('form[name="' + target + '-nav"] input[name="sort_way"]').val($(this).data('defaultSortWay'));

            if($(this).data('type') == 'numeric'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-numeric-'+current_sort_term).show();
                $('.toggle-sort-way').data('type','numeric');
            }else if($(this).data('type') == 'alpha'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-alpha-'+current_sort_term).show();
                $('.toggle-sort-way').data('type','alpha');
            }else if($(this).data('type') == 'amount'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-amount-'+current_sort_term).show();
                $('.toggle-sort-way').data('type','amount');
            }else if($(this).data('type') == 'custom'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-custom-'+current_sort_term).show();
                $('.toggle-sort-way').data('type','custom');
            }else if($(this).data('type') == 'custom-numeric'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-custom-numeric-'+current_sort_term).show();
                $('.toggle-sort-way').data('type','custom-numeric');
            }


        }else if(key == 'list-size'){
            GA.register('Filters','List Size',value);

    
        }



        deepLinking(target);
        filter(target);

        e.preventDefault();
    });

  
    $(document).on('click', '.toggle-sort-way', function () {
        var target = $(this).data('target');
        if ($('form[name="' + target + '-nav"] input[name="sort_way"]').val() == 'DESC') {
            $('form[name="' + target + '-nav"] input[name="sort_way"]').val('ASC');

            if($(this).data('type') == 'numeric'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-numeric-'+current_sort_term).show();
            }else if($(this).data('type') == 'alpha'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-alpha-'+current_sort_term).show();
            }else if($(this).data('type') == 'amount'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-amount-'+current_sort_term).show();
            }else if($(this).data('type') == 'custom'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-custom-'+current_sort_term).show();
            }else if($(this).data('type') == 'custom-numeric'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-custom-numeric-'+current_sort_term).show();
            }

        } else {

            $('form[name="' + target + '-nav"] input[name="sort_way"]').val('DESC');
            if($(this).data('type') == 'numeric'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-numeric-'+current_sort_term).show();
            }else if($(this).data('type') == 'alpha'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-alpha-'+current_sort_term).show();
            }else if($(this).data('type') == 'amount'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-amount-'+current_sort_term).show();
            }else if($(this).data('type') == 'custom'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-custom-'+current_sort_term).show();
            }else if($(this).data('type') == 'custom-numeric'){
                var current_sort_term = $('form[name="' + target + '-nav"] input[name="sort_way"]').val().toLowerCase();
                $('.toggle-sort-way > .fa,.toggle-sort-way > span').hide();
                $('.toggle-sort-way .fa-sort-custom-numeric-'+current_sort_term).show();
            }

        }

        if($('form[name="' + target + '-nav"] input[name="action"]').val() == 'search-products'){
            $('form[name="' + target + '-nav"] input[name="found-single-video"]').val(0);
            $('form[name="' + target + '-nav"] input[name="found-collection"]').val(0);
            $('form[name="' + target + '-nav"] input[name="found-library"]').val(0);
        }
        deepLinking(target);
        filter(target);
        return false;
    });

    $(document).on('click', '.filter-reset', function (e) {
        var key = $(this).data('filterName');
        var value = $(this).data('filterValue');
        var target = $(this).data('target');

        $('form[name="' + target + '-nav"] input[name="' + key + '"]').val(value);
        $('.filter[data-filter-name="' + key + '"]').parent().removeClass("active");
        $('.filter-reset').hide();
        $('.filter-label').hide();
        deepLinking(target);
        filter(target);
        e.preventDefault();
    });

    $(document).on('click','[data-action="filter"]',function(){
        var p = $(this).closest('.partial'), page = $(this).data('page');
        p.data('page',page);
        p.addClass('loading');
        scroll2element($(this).closest('.partial').get());
        deepLinking(p.data());
        load_partial(p);
        return false;
    });



    $(document).on('click','[data-action="load-more"]',function(){
        var p = $(this).closest('.partial'),caller=$(this), page = p.data('page');
       
        p.addClass('loading');
        scroll2element($(this).closest('.partial').get());

         if(page == null || page < 1){
            page = 1;
        }
        caller.remove();  
   
        p.data('page', page + 1);
 
        append_partial( p ,function(){  
            caller.removeClass('loading-list');                      
        }); 

        return false;
    });


    $(document).on('change','[data-action="input-filter"]',function(){
        var p = $(this).closest('.partial'), page = $(this).val(),min = $(this).attr('min'),max = $(this).attr('max');
        if( page<= max && page>=min ){
            p.data('page',page);
            p.addClass('loading');
            scroll2element($(this).closest('.partial').get());
            deepLinking(p.data());
            load_partial(p);
        }else{
            alert('insert a valid number from '+min+' to '+max );
        }
        
        return false;
    });


    init();
    skinFormElements();

    $('.partial[data-async="1"]').each(function(){
        load_partial($(this));
    });
});



function changeUrl(title,href) {
   var stateObj = { title:title };

   history.pushState(stateObj, title , href);

    try{
        ga('send', 'pageview', href);
    }catch(Error){}
}





function refineURL(){

    var currURL = window.location.href; //get current address
    console.log("currURL - "+currURL);   
    
    var beforeQueryString = currURL.split("?")[0];  
    console.log("beforeQueryString - "+beforeQueryString);
    return beforeQueryString;     
}

function openAjaxModal(href,$mm){

    $m = $mm || $modal;
 
    $m.removeData('bs.modal');
    $m.empty();
    $m.load(href,function(){
        $m.modal('show');
        skinFormElements('#'+$m.attr('id'));
    });
}

function openBlockModal(href){

   $.ajax({            
        url      : href,                         
        success: function (data) {    
         $('#modal').append(data.markup);             
            setTimeout(function(){
                $('#modal').modal('show').on('hidden.bs.modal', function () {             
                    $('.modal-dialog').remove();
                }); 
            },200);
        },
        error:function(d){
          
        }
    });
    return false;
}
function init( target ){
 
    
} 

function skinFormElements(target){
 
    var t = typeof(target)!=='undefined' ? target : 'body';

    $(t).find('.show-pass').click(function(){
        var _this = $(this),
            input = _this.closest('.form-group').find('input'),
            type = input.attr('type');
        if(type === 'password'){
            input.attr("type", "text");
             _this.find('.pass-hide').show();    
             _this.find('.pass-show').hide(); 
        }else{
            input.attr("type", "password");
             _this.find('.pass-show').show();
             _this.find('.pass-hide').hide();
        }
    });    
 
    $('[data-toggle="tooltip"]').tooltip();

    $(t).find('select.select2').each(function(){
        
        var options = $(this).data();
        options = $.extend(false, options ,{escapeMarkup:function(m){
            return m;
        }});

        $(this).select2(options).on('select2:open', function () {
            $(this).closest('.form-group').addClass('filled');
            $('.select2-dropdown').closest('.select2-container').addClass('z-index-dropdown');
        }).on('select2:close', function () {
            if( $(this).val() == '0' || $(this).val() == '' || $(this).val() == 'undefined' || $(this).val() == null ){
                $(this).closest('.form-group').removeClass('filled');
            }            
            $('.select2-dropdown').closest('.select2-container').removeClass('z-index-dropdown'); 
        });
    });    
    
    $(t).find(".form-control" ).focusin(function() {
        $(this).parent().addClass('filled');
    });

    $(t).find(".form-control" ).focusout(function() {
        if($(this).val() === "" ){
            $(this).parent().removeClass('filled');
        }
    });


   
}


function prepareItems() {
    $('.item img').each(function () {
        var w = $(this).closest('.item').outerWidth() / $(this).data('ratio');

        $(this).height(w);
    });
}
function initFilters(){    

    $('.side-filters .collapse').on('shown.bs.collapse', function () {

        setTimeout(function(){
            sideBarScroll.refresh();
            skinFormElements('#filters-collapsible');
        },1)
    });

    $('.side-filters .collapse').on('hidden.bs.collapse', function () {
        sideBarScroll.refresh();
    });
    $('body').addClass('filters-menu-expanded');
}

function equalizeHeight(target){
    var min_height = 0;
    setTimeout(function(){
        $( target).each(function(){
            if($(this).outerHeight() > min_height){
                min_height = $(this).outerHeight();
            }
        });
        if(min_height>0){
            $(target).css('min-height',min_height);
        }
    },500);
}
function copyToClipboardFF(text) {
  window.prompt ("Copy to clipboard: Ctrl C, Enter", text);
}

function copyToClipboard(target) {

  var input=$(target).find('input[name="copy_text"]');

  var success   = true,
      range     = document.createRange(),
      selection;

  // For IE.
  if (window.clipboardData) {
    window.clipboardData.setData("Text", input.val());        
  } else {
    // Create a temporary element off screen.
    var tmpElem = $('<div>');
    tmpElem.css({
      position: "absolute",
      left:     "-1000px",
      top:      "-1000px",
    });
    // Add the input value to the temp element.
    tmpElem.text(input.val());
      
    $("body").append(tmpElem);
    // Select temp element.
    range.selectNodeContents(tmpElem.get(0));
    selection = window.getSelection ();
    selection.removeAllRanges ();
    selection.addRange (range);
    // Lets copy.
    try { 
      success = document.execCommand ("copy", false, null);
    }
    catch (e) {
      copyToClipboardFF(input.val());
    }
    if (success) {
      alert ("Copied to clipboard");
      tmpElem.remove();
    }
  }
}

