var Heros = {
    init: function(target) {
        $slider = $(target);
        $slider.slick({
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear'
        });
        if($(target+' .hero-item').length>1 ){
          $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            var current_class = $('header').attr('class');
            var  h_color= nextSlide.data('header-color');
            $('header').addClass(h_color);
            $('header').removeClass(current_class);
          });

        }
       
        $(target+' .scroll-down').click(function(e){
          $("html, body").animate({ scrollTop: $(target).innerHeight() }, 500); 
          e.preventDefault();
        });
    
    }
}