var timeout = false, rtime,delta = 500;

$(function(){ 

    $('.iframe-lang-switch ul li span.lang').click(function(){
        var $this = $(this);
        
        $('.iframe-lang-switch ul li span.lang').removeClass('active');
        
        $this.addClass('active');
        
        var $source = $this.data('source');

        $this.closest('.embed-block').find('iframe').attr('src',$source);

        return false;

    });

    initForm('body');

    
}); 

function waypoints(){

    $('.way-point:not(.way-point-ready)').each(function(){
        
        $(this).waypoint(function() {
            
            $(this.element).addClass('shown');

        }, {offset: '90%'}); 
        
        $(this).addClass('way-point-ready');

    });
    
}

function initForm(target){
	'use strict';
	waypoints();

	$('.select2,select[data-plg="select2"]:visible').each(function(){
		var data = $(this).data();
	    var options = {};
	    if(data.ajax){
	        options = $.extend(false, options ,{
	            placeholder: data.placeholder,
	            allowClear: data.allowClear || false,
	            ajax: {
	                url : API + '/'+ data.ajax,
	                dataType: 'json',
	                delay: 250,
	                data: function (params) {
	                    var search_for = typeof(params.term)!=='undefined'?params.term:'';
	                    return {
	                        q: data.search+'=@'+search_for,
	                        page: params.page
	                    };
	                },
	                processResults: function (res, params) {
	                    params.page = params.page || 1;
	                    var value_field = data.value || 'id';
	                    var label_field = data.label || data.search;
	                    return {
	                        results:  $.map(res.data, function (item) {
	                            return { 
	                                all_data: item,
	                                text: item[label_field],
	                                id: item[value_field]
	                            }
	                        }),
	                        pagination: {
	                            more: (params.page * 30) < data.total_count
	                        }
	                    };
	                },
	                cache: true
	            },
	            minimumInputLength: 0
	        });
	    }
	    $(this).select2(options);
	});
    /*if(target.find('[data-plg="tags"]').length > 0){
        target.find('[data-plg="tags"]').each(function(){
            $(this).select2({
                tags: true,
                tokenSeparators: ['/',',',';'," "]
            });
        });
    }
	if(target.find('[data-toggle="collapse"]').length > 0){
        target.find('[data-toggle="collapse"]').on('click',function(){
            var href = $(this).attr('href');
            setTimeout(function(){
                initForm(href);
            },1);
        });
    }
    $('[data-toggle="tooltip"]').tooltip();
     target.find('input[data-plg="datepicker"]').each(function(){

        var configuration = $(this).data();
		configuration.autoUpdateInput = false;
        configuration.singleDatePicker = true;
		configuration.locale = { format : 'MM.DD.YYYY' };
        $(this).datepicker(configuration);

		$(this).on('apply.datepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('MM.DD.YYYY'));
			$(this).trigger('change');
	  	});

		$(this).on('cancel.datepicker', function(ev, picker) {
		  	$(this).val('');
			$(this).trigger('change');
		});

    });
    */
}

function actionResponseModal(msg, type, container){
	var $timeout = 0;
	
	if($('body').hasClass('modal-open') ){
		$('.modal').modal('hide');
		$timeout = 500;	
	}

	setTimeout(function(){

        var $d = '<div class="modal fade" id="responseModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">\
                <div class="modal-dialog modal-lg" role="document">\
                    <div class="modal-content">\
                        <a href="#" class="close-modal" tabindex="0" onClick="$(\'#responseModal\').modal(\'hide\');return false;">\
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 13 13" style="enable-background:new 0 0 13 13;" xml:space="preserve">\
                                <path class="st0" d="M12.9,11.7l-1.2,1.2L6.9,8.1l-4.8,4.8l-1.2-1.2l4.8-4.8L0.9,2.1l1.2-1.2l4.8,4.8l4.8-4.8l1.2,1.2L8.1,6.9 L12.9,11.7z"></path>\
                            </svg>\
                        </a>\
                        <div class="modal-body"></div>\
                    </div>\
                </div>\
            </div>';
        if( $('#responseModal').length == 0 ){
            $('body').append($d);
        }
        
		var _container = container || '';
		if(type == 'success'){
			var icon = '<i class="fa fa-check-circle" aria-hidden="true"></i>';	
		}else{
			var icon = '<i class="fa fa-exclamation-circle" aria-hidden="true"></i>';	
		}
		var content = '<div class="alert alert-'+type+'"><h3>'+msg+'</h3></div>'
		$('#responseModal .modal-body').html(content);	
		$('body').addClass('dark-backdrop');
		$('#responseModal').modal('show');
		$('#responseModal').on('hidden.bs.modal', function () {
			$('#responseModal .modal-body').empty();
			$('body').removeClass('dark-backdrop');
		});
	},$timeout);

}

function in_array(needle, haystack, argStrict) {
    var key = '', strict = !!argStrict;
    if (strict) {
        for (key in haystack) {
            if (haystack[key] === needle) {
                return true;
            }
        }
    }
    else {
        for (key in haystack) {
            if (haystack[key] == needle) {
                return true;
            }
        }
    }
    return false;
}
 
 
function setCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) 
{
   return setCookie(name,"",-1);
}

function parseQuery(str)
{
    if(typeof str !== "string" || str.length === 0) return {};
    var s = str.split("&");
    var s_length = s.length;
    var bit, query = {}, first, second;
    for(var i = 0; i < s_length; i++)
    {
        bit = s[i].split("=");
        first = decodeURIComponent(bit[0]);
        if(first.length === 0) continue;
        second = decodeURIComponent(bit[1]);
        if(typeof query[first] === "undefined") query[first] = second;
        else if(query[first] instanceof Array) query[first].push(second);
        else query[first] = [query[first], second];
    }
    return query;
}

function scrollY() {
    return window.pageYOffset || window.document.documentElement.scrollTop;
}
function scroll2position(pos,t) {
    $('html, body').animate({scrollTop: pos}, t || 500);
}
function scroll2element(el) {
    var position = $(el).offset();
    var $padding = $body.css('paddingTop').replace('px','');
    var y = position.top - $padding;
    
    $('html, body').stop().animate({scrollTop: y}, 'slow');

}

function viewport() {
    return {
        height: $(window).innerHeight(),
        width: $(window).innerWidth(),
        breakPoint: function() {
            var w = this.width;
            if (w <= 1200) {
                if (w <= 992) {
                    if (w <= 768) {
                        return 'xsmall';
                    } else {
                        return 'small';
                    }
                } else {
                    return 'medium';
                }
            } else {
                return 'large';
            }
        }
    }
}


function currency_format($amount, $currency) {
    return $currency + Number($amount, 2).toFixed(2);
}


function missingImageLoad(){
    var attempts = $(this).data('attempts');
    var src = $(this).attr('src').replace('//s3.iknowauto.com/', '//api.iknowauto.com/');
    $(this).attr('src',src);
    $(this).off('error',missingImageLoad);
}