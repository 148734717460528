
var newsFilters = {
    init: function($target) {
        $('.news-list-filters-item').removeClass('active');
        $('.news-list-filters-item[data-category-type="'+$target+'"]').addClass('active');
        var caller = $('.news-list-filters-item[data-category-type="'+$target+'"]'),
            partial = $('.news-grid.partial');
            partial.data('category_type',$target);
            partial.data('page', '1');
            load_partial(partial);
            
    }
}